import gql from 'graphql-tag';

export const retrieveDetectionTaskQuery = gql`
	query retrieveDetectionTaskQuery($fileId: UUID) {
		detectionTask(fileId: $fileId) {
			id
			fileId
			locationId
			performedAt
			results
			performedBy
			updatedAt
		}
	}
`;
