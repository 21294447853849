import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { retrieveDetectionTaskQuery } from '@app/pages/cmms/graphql/incident-detection.queries';
import {
	CreateDetectionTaskMutation,
	UpdateDetectionTaskMutation,
} from '../graphql/incident-detection.mutations';

import {
	ICreateDetectionTaskVariables,
	IDetectionResult,
	IGetDetectionResult,
	IIncidentDetection,
} from '../interfaces/incident-detection.interfaces';
import { map } from 'rxjs';

export interface IUpdateDetectionTaskVariables {
	fileId: string;
	results: string;
}

@Injectable({
	providedIn: 'root',
})
export class IncidentDetectionService {
	private readonly apollo = inject(Apollo);

	createDetectionTask(variables: ICreateDetectionTaskVariables) {
		return this.apollo
			.use('dexter')
			.mutate<IDetectionResult>({
				mutation: CreateDetectionTaskMutation,
				variables,
			})
			.pipe(map(res => res?.data?.createDetectionTask?.detectionTask));
	}

	retrieveDetectionTask(variables: IIncidentDetection) {
		return this.apollo
			.use('federation')
			.query<IGetDetectionResult>({
				query: retrieveDetectionTaskQuery,
				variables,
				fetchPolicy: 'no-cache',
			})
			.pipe(map(res => res?.data?.detectionTask));
	}

	updateDetectionResult(variables: IUpdateDetectionTaskVariables) {
		return this.apollo
			.use('federation')
			.mutate<IGetDetectionResult>({
				mutation: UpdateDetectionTaskMutation,
				variables,
			})
			.pipe(map(res => res?.data?.detectionTask));
	}
}
