import gql from 'graphql-tag';

export const CreateDetectionTaskMutation = gql`
	mutation CreateDetectionTaskMutation(
		$fileId: UUID!
		$locationId: UUID
		$performedBy: UUID!
		$url: String!
	) {
		createDetectionTask(
			fileId: $fileId
			locationId: $locationId
			performedBy: $performedBy
			url: $url
		) {
			detectionTask {
				id
				fileId
				locationId
				performedBy
				results
			}
		}
	}
`;
export const UpdateDetectionTaskMutation = gql`
	mutation updateDetectionTaskMutation($fileId: UUID!, $results: String!) {
		updateDetectionTask(fileId: $fileId, results: $results) {
			detectionTask {
				results
			}
		}
	}
`;
